import * as React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import Spacer from "../components/spacer";
import MainWrapper from "../components/siteWrapper";

// typography

const SubHeading = styled.h2``;

// -------- end typography

const Hr = styled.hr`
  width: 60%;
  margin: 64px auto 64px auto;
`;

const GoogleMapEmbed = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d651.6891491130677!2d-122.90962635671896!3d49.205176824568575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d87242dfce81%3A0xc74f9dba01c26c0d!2s508%20Agnes%20St%2C%20New%20Westminster%2C%20BC%20V3L%201G2!5e0!3m2!1sen!2sca!4v1659635432474!5m2!1sen!2sca"
const GoogleMapStyle = {
  border: `0`,
}
const Contact = () => (
  <Layout>
    {/*Google Map Start*/}
    <iframe
        title="Perfection Lodge No 75 on Google Maps"
        src={GoogleMapEmbed}
        width="100%" height="400" style={GoogleMapStyle} allowFullScreen=""
        loading="eager" referrerPolicy="no-referrer-when-downgrade" />
    {/*Google Map End*/}
    <MainWrapper>

      <Spacer />
      <center>
        <h1>How to contact Perfection Lodge No 75</h1>

        <div>
          <SubHeading>Interested in joining the oldest Fraternity in the world?</SubHeading>
          <p>
            Email{" "}
            <a
              href="mailto:WMcRobbie@hotmail.com"
              title="join the freemasons at perfection lodge no 75"
            >
              WMcRobbie@hotmail.com
            </a>{" "}
            to reach the membership committee.
          </p>
          <Hr />
          <SubHeading>For lodge business,</SubHeading>
          <p>
            Email our secretary at{" "}
            <a
              href="mailto:jmcmurchie56@gmail.com"
              title="email perfection lodge no 74 secretary"
            >
              jmcmurchie56@gmail.com
            </a>
          </p>
        </div>
      </center>
    </MainWrapper>
  </Layout>
);

export default Contact;

export function Head( {location} ) {

  const title = "Contact Perfection Lodge No.75, Freemasons.";
  const description = "Interested in talking or learning more? Reach us here..";
  const siteURL = "https://newwestfreemasons.ca"

  return (
      <>
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Perfection Lodge No. 75, New Westminster" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="ca_EN" />
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content={`${siteURL}${location.pathname}/`}
        />
        <meta property="og:image" content="https://newwestfreemasons.ca/logo.png" />
        <link rel="canonical" href={`${siteURL}${location.pathname}/`} />
      </>
  )
}