import * as React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  height: 50px;
`;

const Spacer = () => <Wrapper />;

export default Spacer;
